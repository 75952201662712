import React from 'react';

import SEO from 'src/components/SEO';
import ContactPage from 'src/components/pages/ContactPage';

const Contact = () => (
    <>
      <SEO title="Contact" />
      <ContactPage />
    </>
  );

export default Contact;
